import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { useTranslation } from "react-i18next"

import { Container, makeStyles, Grid, Box, Typography } from "@material-ui/core"

import SEO from "../components/seo"
import BookList from "../components/book-list"
import Breadcrumbs from "../components/breadcrumbs"

import BgImage from "../../static/images/aboutBg.svg"
// import CategoryBg from "../../static/images/mainBg.jpg"
import Logo from "../../static/images/logo/logo_signup.svg"
import StoreIcons1 from "../../static/images/Icons/store-icon.svg"
import StoreIcons2 from "../../static/images/Icons/store-icon-2.svg"
import CategoryImg from "../../static/images/Icons/categoryImage.svg"
import FooterCategory1 from "../../static/images/Icons/CategoryIcons1.svg"
import FooterCategory2 from "../../static/images/Icons/CategoryIcons2.svg"
import FooterCategory3 from "../../static/images/Icons/CategoryIcons3.svg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  sideBarGrid: {
    backgroundColor: "#f5f5f5",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 50,
      "&:after": {
        content: "''",
        backgroundImage: "linear-gradient(180deg, #f5f5f5, transparent)",
        position: "absolute",
        left: 0,
        bottom: "-50px",
        height: 50,
      },
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      paddingBottom: "2rem",
      "&:after": {
        content: "",
        display: "none",
      },
    },
  },
  contentGrid: {
    marginLeft: 0,
    paddingLeft: 0,
    position: "relative",
    zIndex: "3",
  },
  categorySideBarImage: {
    width: 180,
    borderRadius: 10,
    textAlign: "center",
    margin: "4rem auto 0",
    padding: 38,
  },
  sideBarContainer: {
    textAlign: "center",
  },
  categoryDescription: {
    marginRight: "2.6rem",
    marginLeft: "2.6rem",
    marginBottom: "2.6rem",
  },
  categorySideBarContent: {
    width: 180,
    margin: "2rem auto 0",
    textAlign: "left",
  },
  categorySideBarItem: {
    color: "#707070",
    textDecoration: "none",
    "& p": {
      lineHeight: "34px",
    },
  },
  categoryActive: {
    color: "#003e96",
    "& p": {
      fontWeight: "bold",
    },
  },
  categorySideBarLogo: {
    margin: "0 1rem 1rem",
    width: "calc(100% - 40px)",
  },
  categorySideBarBottom: {
    width: 200,
    margin: "2rem auto 1rem",
  },
  categorySideBarBottomText: {
    fontSize: "0.8rem",
    marginBottom: "0.8rem",
  },
  typesIcons: {
    "& img": {
      height: 60,
      width: "auto",
    },
  },
  categorySideBarStoreIcons1: {
    "& img": {
      height: 42,
      marginTop: 1,
    },
  },
  categorySideBarStoreIcons2: {
    "& img": {
      height: 42,
    },
  },
  categoryGridContainer: {
    position: "relative",
    "&:first-child": {
      alignItems: "stretch",
    },
    "&:last-child": {
      alignItems: "flex-start",
    },
  },
  categoryBoxes: {
    textAlign: "center",
    color: "white",
    paddingTop: 20,
    paddingBottom: 20,
    border: "solid 1px #707070",
    borderRadius: 10,
    "& p": {
      textShadow: "0 10px 6px rgba(0, 0, 0, 0.16)",
      fontSize: "1.3rem",
    },
  },
  categoryLink: {
    textDecoration: "none",
    "& img": {
      maxHeight: 76,
      maxWidth: "90%"
    },
  },
  secTitleCategories: {
    marginBottom: "1.5rem",
    textAlign: "center",
    color: "#003e96",
  },
  categorySideBarMainItem: {
    color: "#003e96",
  },
  categoriesSection: {
    position: "relative",
    backgroundColor: "#e9e9e9",
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    margin: 0,
    paddingTop: "3rem",
    paddingBottom: "3rem",
    maxWidth: "100%",
    "&:after": {
      content: "''",
      backgroundImage: `url(${BgImage})`,
      backgroundSize: "cover",
      backgroundPosition: "150px 250px",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      zIndex: "2",
    },
    "& > div": {
      position: "relative",
      zIndex: 3,
    },
  },
  categoriesSectionBooks: {
    // backgroundImage: `url(${CategoryBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(5),
    margin: 0,
    maxWidth: "100%",
  },
}))

const Categories = ({ data, pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()

  const { pLang } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "categories",
        `/${i18n.language}/categories`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "categories",
          `/${i18n.language}/categories`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/categories`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("breadcrumbs.categories"),
    },
  ]

  const books = []
  for (let book of data.allStrapiBooks.nodes) {
    let objCopy = Object.assign({}, book)
    books.push(objCopy)
  }

  return (
    <>
      <SEO title={t("breadcrumbs.categories")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <Container className={`${classes.fullWidth} ${classes.heroBg}`}>
        <Grid container className={classes.categoryGridContainer}>
          <Grid
            item
            sm={12}
            xs={12}
            md={3}
            className={classes.sideBarGrid}
            component="div"
          >
            <Container className={classes.sideBarContainer}>
              <Box className={classes.categorySideBarContent}>
                {data.allStrapiCategories.nodes
                  .filter(cat => (cat.parent_cat ? false : true))
                  .map(category => (
                    <div key={category.strapiId}>
                      <Link
                        to={`/${i18n.language}/categories/${category.strapiId}`}
                        activeClassName={classes.categoryActive}
                        className={`${classes.categorySideBarItem} ${classes.categorySideBarMainItem}`}
                      >
                        <Typography>
                          {i18n.language === "en" && category.title}
                          {i18n.language === "ar" && category.title_ar}
                          {i18n.language === "tr" && category.title_tr}
                        </Typography>
                      </Link>
                      {category.sub_cats.length > 0 &&
                        category.sub_cats.map(cat => (
                          <Link
                            key={cat.id}
                            to={`/${i18n.language}/categories/${cat.id}`}
                            activeClassName={classes.categoryActive}
                            className={classes.categorySideBarItem}
                          >
                            <Typography>
                              {i18n.language === "en" && cat.title}
                              {i18n.language === "ar" && cat.title_ar}
                              {i18n.language === "tr" && cat.title_tr}
                            </Typography>
                          </Link>
                        ))}
                    </div>
                  ))}
              </Box>
              <Box className={classes.categorySideBarBottom}>
                <img
                  src={Logo}
                  alt="sideBar logo"
                  className={classes.categorySideBarLogo}
                />
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.categorySideBarBottomText}
                >
                  {t("home.heroText1")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.categorySideBarBottomText}
                >
                  {t("home.heroText2")}
                </Typography>

                <Box display="flex" justifyContent="center">
                  <Box p={1} m={1} className={classes.typesIcons}>
                    <img src={FooterCategory1} alt="Foliar" />
                    <Typography variant="subtitle1">
                      {t("footer.foliar")}
                    </Typography>
                  </Box>
                  <Box p={1} m={1} className={classes.typesIcons}>
                    <img src={FooterCategory2} alt="Electronic" />
                    <Typography variant="subtitle1">
                      {t("footer.electronic")}
                    </Typography>
                  </Box>
                  <Box p={1} m={1} className={classes.typesIcons}>
                    <img src={FooterCategory3} alt="Vocal" />
                    <Typography variant="subtitle1">
                      {t("footer.vocal")}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.categorySideBarStoreIcons1}>
                    <a href="https://play.google.com/store/apps/details?id=com.arafas.kitabhadef">
                      <img src={StoreIcons1} alt="Google Play" />
                    </a>
                  </Box>
                  <Box className={classes.categorySideBarStoreIcons2}>
                    <a href="https://apps.apple.com/us/app/كتابي-الهادف/id1565685455">
                      <img src={StoreIcons2} alt="App Store" />
                    </a>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Grid>
          <Grid
            item
            container
            sm={12}
            md={9}
            className={classes.contentGrid}
            component="div"
          >
            {data.allStrapiCategories.nodes.length > 0 && (
              <Container className={classes.categoriesSection}>
                <Container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      component="h2"
                      className={classes.secTitleCategories}
                    >
                      {t("home.mainCategoriesTitle")}
                    </Typography>
                  </Grid>
                  <Grid container spacing={4} className={classes.categoryGrid}>
                    {data.allStrapiCategories.nodes.map(
                      (category, key) =>
                        !category.parent_cat &&
                        (i18n.language === "en" && category.title ? (
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            lg={3}
                            md={3}
                            key={key}
                            className={classes.categoryGridItem}
                          >
                            <Link
                              to={`/${i18n.language}/categories/${category.strapiId}`}
                              className={classes.categoryLink}
                            >
                              <Box
                                p={1}
                                bgcolor={
                                  category.backgroundColor
                                    ? category.backgroundColor
                                    : "rgb(0, 62, 150)"
                                }
                                className={classes.categoryBoxes}
                              >
                                <img
                                  p={3}
                                  src={
                                    category.image
                                      ? category.image.publicURL
                                      : CategoryImg
                                  }
                                  alt={category.title_ar}
                                />
                                <Typography variant="h5" component="p">
                                  {category.title}
                                  {/* {i18n.language === "ar" && category.title_ar}
                                {i18n.language === "tr" && category.title_tr} */}
                                </Typography>
                              </Box>
                            </Link>
                          </Grid>
                        ) : i18n.language === "ar" && category.title_ar ? (
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            lg={3}
                            md={3}
                            key={key}
                            className={classes.categoryGridItem}
                          >
                            <Link
                              to={`/${i18n.language}/categories/${category.strapiId}`}
                              className={classes.categoryLink}
                            >
                              <Box
                                p={1}
                                bgcolor={
                                  category.backgroundColor &&
                                  category.backgroundColor.startsWith("#")
                                    ? category.backgroundColor
                                    : category.backgroundColor !== null
                                    ? `#${category.backgroundColor}`
                                    : "rgb(0, 62, 150)"
                                }
                                className={classes.categoryBoxes}
                              >
                                <img
                                  p={3}
                                  src={
                                    category.image
                                      ? category.image.publicURL
                                      : CategoryImg
                                  }
                                  alt={category.title_ar}
                                />
                                <Typography variant="h5" component="p">
                                  {/* {i18n.language === "en" && category.title} */}
                                  {category.title_ar}
                                  {/* {i18n.language === "tr" && category.title_tr} */}
                                </Typography>
                              </Box>
                            </Link>
                          </Grid>
                        ) : (
                          i18n.language === "tr" &&
                          category.title_tr && (
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              lg={3}
                              md={3}
                              key={key}
                              className={classes.categoryGridItem}
                            >
                              <Link
                                to={`/${i18n.language}/categories/${category.strapiId}`}
                                className={classes.categoryLink}
                              >
                                <Box
                                  p={1}
                                  bgcolor={
                                    category.backgroundColor &&
                                    category.backgroundColor.startsWith("#")
                                      ? category.backgroundColor
                                      : category.backgroundColor !== null
                                      ? `#${category.backgroundColor}`
                                      : "rgb(0, 62, 150)"
                                  }
                                  className={classes.categoryBoxes}
                                >
                                  <img
                                    p={3}
                                    src={
                                      category.image
                                        ? category.image.publicURL
                                        : CategoryImg
                                    }
                                    alt={category.title_tr}
                                  />
                                  <Typography variant="h5" component="p">
                                    {/* {i18n.language === "en" && category.title}
                                  {i18n.language === "ar" && category.title_ar} */}
                                    {category.title_tr}
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                          )
                        ))
                    )}
                  </Grid>
                </Container>
              </Container>
            )}
            <Container className={classes.categoriesSectionBooks}>
              <BookList books={books} />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default Categories

export const query = graphql`
  query Categories {
    allStrapiCategories(sort: { fields: id }) {
      nodes {
        id
        strapiId
        title
        title_ar
        title_tr
        backgroundColor
        image {
          publicURL
          childImageSharp {
            fixed(height: 76) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        parent_cat {
          id
          title
          title_ar
          title_tr
          image {
            publicURL
            childImageSharp {
              fixed(height: 76) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        sub_cats {
          id
          title
          title_ar
          title_tr
          parent_cat
          image {
            publicURL
            childImageSharp {
              fixed(height: 76) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allStrapiBooks {
      nodes {
        id
        ISBN
        title
        title_ar
        title_tr
        rating
        quantity
        strapiId
        image {
          publicURL
          childImageSharp {
            fixed(width: 169, height: 244) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        book_items {
          id
          price
          title
          type
        }
      }
    }
  }
`